import React from 'react';
import { Switch } from 'react-router-dom';
import { DataDogRumRoute } from 'iqm-framework';

import { routes, Route as RouteType } from '../../routes';
import { Scene } from '../Layout/Scene';

export const PrivateRoutes = () => {
  const routePaths = routes.map((route: RouteType) => route.path);
  return (
    <Switch>
      <DataDogRumRoute path={routePaths} exact>
        <Scene>
          <Switch>
            {routes.map((route) => (
              <DataDogRumRoute
                key={route.path}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
          </Switch>
        </Scene>
      </DataDogRumRoute>
    </Switch>
  );
};

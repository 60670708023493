import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IQMRouter, DataDogRumRoute, bootIntercomFn } from 'iqm-framework';

import { authActions, Authorized, Logout } from 'store/auth/actions';
import { User } from 'models/User';
import { createIqmInstance, API } from 'api';
import { Navbar } from 'components/Navbar';
import { ResetPasswordEmailPage } from 'pages/ResetPasswordEmailPage';
import { ResetPasswordPage } from 'pages/ResetPasswordPage';
import { InviteCustomerPage } from 'pages/InviteCustomerPage';
import { InviteExpiredPage } from 'pages/InviteExpiredPage';
import { LoginForm } from 'pages/LoginPage';
import { DefaultPage } from 'pages/DefaultPage';
import { CreateWorkspacePage } from 'pages/CreateWorkspacePage';
import { ErrorPage } from 'pages/ErrorPage';
import { CreatePasswordPage } from 'pages/CreatePasswordPage';
import { EnterWorkspacePage } from 'pages/EnterWorkspacePage';
import { FindMyWorkspacePage } from 'pages/FindMyWorkspacePage';
import { AccessPage } from 'pages/AccessPage';
import { PrivateRoutes } from '../PrivateRoutes';

import './App.scss';

/* eslint-disable */
declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}
/* eslint-enable */

type Props = Authorized & Logout;

const AppComponent = (props: Props) => {
  const { authorized, logout } = props;
  const [intercomId, setIntercomId] = useState<string | undefined>('');

  const onSuccessLogin = (data: User) => {
    authorized(data);
    createIqmInstance({
      apiToken: data.apiToken,
      domainURL: data.domainURL,
    });
  };

  const setIntercom = async () => {
    try {
      const data = await API.Customer.getIntercomId();
      setIntercomId(data.data?.chatbotAppId);
    } catch (e) {
      // eslint-disable-next-line
      console.log('intercom:', e);
    }
  };

  useEffect(() => {
    setIntercom();
    window.onstorage = null;
  }, []);

  useEffect(() => {
    if (intercomId) {
      bootIntercomFn(intercomId);
    }
  }, [intercomId]);

  useEffect(() => {
    if (intercomId) {
      try {
        // eslint-disable-next-line
        window?.Intercom?.('boot', {
          app_id: intercomId,
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log('error initalizing intercom', e);
      }
    }
    return () => {
      // eslint-disable-next-line
      window.Intercom?.('shutdown');
    };
  }, [intercomId]);

  const onLogout = () => logout();

  return (
    <IQMRouter
      documentTitle="Loading..."
      successLoginRedirectPath="/landing"
      onSuccessLogin={onSuccessLogin}
      onLogout={onLogout}
      loginForm={LoginForm}
      publicRoutes={() => (
        <>
          <DataDogRumRoute path="/reset-password-email" component={ResetPasswordEmailPage} />
          <DataDogRumRoute path="/reset-password" component={ResetPasswordPage} />
          <DataDogRumRoute path="/invite" component={InviteCustomerPage} />
          <DataDogRumRoute path="/invite-expired" component={InviteExpiredPage} />
          <DataDogRumRoute path="/create-workspace" component={CreateWorkspacePage} />
          <DataDogRumRoute path="/enter-workspace" component={EnterWorkspacePage} />
          <DataDogRumRoute path="/find-my-workspace" component={FindMyWorkspacePage} />
          <DataDogRumRoute path="/create-password" component={CreatePasswordPage} />
          <DataDogRumRoute path="/404" component={ErrorPage} />
          <DataDogRumRoute path="/landing" component={DefaultPage} />
          <DataDogRumRoute path="/signup">
            <Redirect to="/login" />
          </DataDogRumRoute>
          <DataDogRumRoute path="/access" component={AccessPage} />
          <DataDogRumRoute exact path="/">
            <Redirect to="/login" />
          </DataDogRumRoute>
        </>
      )}
    >
      <Navbar />
      <PrivateRoutes />
    </IQMRouter>
  );
};

const mapAction = {
  authorized: authActions.authorized,
  logout: authActions.logout,
};

export const App = connect(null, mapAction)(AppComponent);

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  TwoPartScreen,
  TwoPartScreenStyles,
  userAuth,
  AuthService,
  ORIGIN_URL,
} from 'iqm-framework';
import { SVG_HOST_URL, Button, Pill } from 'factor';
import get from 'lodash/get';

import { PasswordField } from 'components/PasswordField';
import { API } from 'api';
import { VerifyLinkResponse } from 'api/customer';
import { hintsList, THint } from 'models/Password';
import { User } from 'models/User';
import { useInitData } from 'utils/useInitData';

import styles from './index.module.scss';

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/office-people.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/brand/group12.svg`;

interface LocationData extends VerifyLinkResponse {
  hash: string;
}

export const CreatePasswordPage = () => {
  const [visibleCard, setVisibleCard] = useState<1 | 2>(1);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [passwrd, setPasswrd] = useState<string>('');
  const [hints, setHints] = useState<THint[]>(hintsList);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [workspaceUrl, setWorkspaceUrl] = useState<string>('');
  const [owId, setOwId] = useState<number>();
  const history = useHistory();
  const location = useLocation<LocationData>();

  const { sideURL, data: initData } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });

  useEffect(() => {
    if (!location.state?.inviteEmail) {
      history.push('/404');
    } else if (location.state.invitedToWorkspace) {
      setEmailAddress(location.state.inviteEmail);
      setWorkspaceUrl(location.state.invitedToWorkspace);
    }
  }, [history, location]);

  useEffect(() => {
    if (passwrd.length) {
      const hintsToSave = hintsList.map((h) => ({ ...h, match: h.regex.test(passwrd) }));
      setHints(hintsToSave);
    } else {
      setHints(hintsList);
    }
  }, [passwrd]);

  useEffect(() => {
    if (visibleCard === 2 && owId != null) {
      setTimeout(() => {
        if (ORIGIN_URL.includes(workspaceUrl)) {
          // we're in the correct workspace URL already
          window.location.href = '/marketplace/u/0';
        } else {
          window.location.href = `https://${workspaceUrl}/#/login?owner=${btoa(
            ORIGIN_URL,
          )}&ow=${owId}`;
        }
      }, 4000);
    }
  }, [emailAddress, owId, visibleCard, workspaceUrl]);

  const handleCreatePassword = async () => {
    if (location.state.inviteEmail) {
      const res = await API.Password.createPassword(
        {
          email: location.state.inviteEmail,
          password: passwrd,
          inviteHash: location.state.hash,
        },
        workspaceUrl,
      );

      if (res.success && res.data) {
        if (res?.data?.redirectionUrl) {
          window.location.href = res.data.redirectionUrl;
          return;
        }
        const authorizedUser: Partial<User> = userAuth(res.data);
        localStorage.setItem('authorizedUser', JSON.stringify(authorizedUser));
        setOwId(res.data.owId);
        if (ORIGIN_URL.includes(workspaceUrl)) {
          localStorage.setItem('owIds', JSON.stringify([res.data.owId]));
          AuthService.authorize(authorizedUser, true);
        }

        setVisibleCard(2);
      } else {
        setErrorMsg(get(res, 'errorObjects[0].error', ''));
      }
    }
  };

  return (
    <TwoPartScreen
      rightPartImage={sideURL}
      docTitle="Create Password"
      leftChildren={
        <>
          <div
            className={`${TwoPartScreenStyles.card} ${
              visibleCard === 1 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
            }`}
          >
            <div className={TwoPartScreenStyles.title}>Create new password</div>
            <div className="mt-3">
              <Pill label={emailAddress} iconName="Mail" />
            </div>
            <Pill label={workspaceUrl} iconName="Internet" />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreatePassword();
              }}
            >
              <div>
                <input type="password" style={{ opacity: '0', position: 'absolute' }} />
                <PasswordField
                  className={styles.passwordField}
                  label="Create Password"
                  placeholder="Enter new password"
                  value={passwrd}
                  helpText={initData?.passwordNote ? '' : 'Hints for a stronger password'}
                  onChange={setPasswrd}
                  name="password"
                  inputAttributes={{
                    autoComplete: 'new-password',
                    autoFocus: true,
                  }}
                />
                <div className={styles.passwordNote}>
                  {initData?.passwordNote ? `Note: ${initData.passwordNote}` : ''}
                </div>

                <div className={styles.passwordHints}>
                  {initData?.passwordNote ? 'Hints for a stronger password' : ''}
                </div>
                <ul className={styles.hints}>
                  {hints.map((h) => (
                    <li
                      className={`${styles.hint} ${styles[h.match ? 'green' : 'red']}`}
                      key={h.label}
                    >
                      {h.label}
                    </li>
                  ))}
                </ul>
                <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div>
              </div>

              <Button
                variant="primary"
                iconName="Right"
                className={TwoPartScreenStyles.button}
                iconPosition="right"
                disabled={!emailAddress || hints.find((hint) => !hint.match)}
                type="submit"
              >
                Next
              </Button>
            </form>
          </div>
          <div
            className={`${TwoPartScreenStyles.card} ${
              visibleCard === 2 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
            }`}
          >
            <div className={TwoPartScreenStyles.title}>Password created successfully</div>
            <div className={TwoPartScreenStyles.subtitle}>
              Welcome to {location.state?.organizationName || 'IHP'}!
            </div>
          </div>
        </>
      }
    />
  );
};

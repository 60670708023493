import React from 'react';

import { MainContent } from '../MainContent';
import { Layout } from '.';

import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
}

export const Scene = (props: Props) => {
  const { children } = props;

  return (
    <Layout sidebar={<>{children}</>}>
      <MainContent>
        <div className={styles.mainTitle}>All Conversions (45)</div>
      </MainContent>
    </Layout>
  );
};

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  TwoPartScreen,
  TwoPartScreenStyles,
  useAdblockCheck,
  validationUtils,
} from 'iqm-framework';
import { SVG_HOST_URL, TextField, Button } from 'factor';

import { API } from 'api';
import { useInitData } from 'utils/useInitData';
import styles from './index.module.scss';

interface LocationData {
  userEmail: string;
}

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/office-people.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/brand/onboarding1Asset2.svg`;
const CHECK_CIRCLE_IMAGE_URL = `${SVG_HOST_URL}/check_circle.svg`;

export const FindMyWorkspacePage = () => {
  const [visibleCard, setVisibleCard] = useState<1 | 2>(1);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [emailValidity, setEmailValidity] = useState<boolean>(false);
  const [adblockEnabled] = useAdblockCheck();
  const history = useHistory();
  const location = useLocation<LocationData>();

  const { sideURL } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });

  useEffect(() => {
    if (location.state?.userEmail) {
      setEmailAddress(location.state.userEmail);
    }
  }, [history, location]);

  // reset error if email changes
  useEffect(() => {
    setErrorMsg('');
  }, [emailAddress]);

  const findMyWorkspace = async () => {
    const res = await API.Customer.findMyWorkspace(emailAddress);

    if (res.success) {
      setVisibleCard(2);
    } else {
      setErrorMsg('Email ID does not exist. Please enter a valid email ID.');
    }
  };

  return (
    <TwoPartScreen
      rightPartImage={sideURL}
      docTitle="Find my workspace"
      leftChildren={
        <>
          <div
            className={`${TwoPartScreenStyles.card} ${
              visibleCard === 1 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
            }`}
          >
            <div className={TwoPartScreenStyles.title}>Find your workspace</div>
            <div className={TwoPartScreenStyles.subtitle}>
              We will help you find existing workspaces associated with your email address
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                findMyWorkspace();
              }}
            >
              <div>
                <TextField
                  variant="withoutTickbox"
                  name="email"
                  value={emailAddress}
                  onChange={setEmailAddress}
                  label="Email ID"
                  placeholder="Enter your email ID"
                  className="mt-5"
                  onValidate={setEmailValidity}
                  inputAttributes={{
                    autoComplete: 'on',
                    autoFocus: true,
                  }}
                  validationRules={[
                    {
                      func: validationUtils.validateEmail,
                      error: () => {
                        return 'Please enter a valid email address';
                      },
                    },
                  ]}
                />
              </div>
              <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div>

              <Button
                variant="primary"
                iconName="Right"
                className={TwoPartScreenStyles.button}
                iconPosition="right"
                disabled={adblockEnabled || !emailValidity}
                type="submit"
              >
                Next
              </Button>
            </form>
          </div>
          <div
            className={`${TwoPartScreenStyles.card} ${
              visibleCard === 2 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
            } ${styles.urlsEmailSend}`}
          >
            <img src={CHECK_CIRCLE_IMAGE_URL} alt="CHECK_CIRCLE" />
            <div className={TwoPartScreenStyles.title}>URLs Email sent</div>
            <div className={TwoPartScreenStyles.subtitle}>
              We have sent you URLs on the associated email.
              <br />
              Check your email for logging in your workspaces.
            </div>
          </div>
        </>
      }
    />
  );
};

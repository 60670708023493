import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TwoPartScreen, TwoPartScreenStyles } from 'iqm-framework';
import { SVG_HOST_URL, ProgressCircle } from 'factor';

import { API } from 'api';
import { useInitData } from 'utils/useInitData';

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/office-people.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/brand/onboarding1Asset2.svg`;
const INVALID_LINK = 'The invitation link is invalid.';

export const InviteCustomerPage = () => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { sideURL } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });

  useEffect(() => {
    const verifyHash = async (hash: string) => {
      const hash1 = hash.replace(/\s/g, '+');
      const res = await API.Customer.verifyInviteLinkHash(hash1);

      // invite expired
      if (res.data?.isExpired) {
        history.push({
          pathname: '/invite-expired',
          search: history.location.search,
        });

        // invite cancelled
      } else if (res.data?.isInviteCancelled) {
        history.push('/404');
      } else if (res.success && (res.data?.isInviteProcessed || res.data?.isInviteDeleted)) {
        setErrorMsg(INVALID_LINK);

        // if a user with the invited email already exists in the system
      } else if (
        res.success &&
        !res.data?.isNewUser &&
        res.data?.invitedToWorkspace &&
        res.data.inviteEmail
      ) {
        window.location.href = `https://${res.data.invitedToWorkspace}/#/login?email=${btoa(
          res.data.inviteEmail,
        )}`;

        // invite from IHB
      } else if (res.success && res.data?.isIHPCustomer) {
        // if a workspace was already created with this invite
        if (res.data.workspaceDomain) {
          history.push({
            pathname: '/create-password',
            state: { ...res.data, hash: hash1, invitedToWorkspace: res.data.workspaceDomain },
          });

          // if no workspace already created
        } else {
          history.push({
            pathname: '/create-workspace',
            state: { hash: hash1, ...res.data },
          });
        }

        // invite from non-IHB
      } else if (res.success && !res.data?.isIHPCustomer) {
        history.push({
          pathname: '/create-password',
          state: { hash: hash1, ...res.data },
        });
      } else {
        setErrorMsg(INVALID_LINK);
      }
    };

    if (history.location) {
      const hash = new URLSearchParams(history.location.search).get('hash');
      if (hash) {
        verifyHash(hash);
      }
    }
  }, [history]);

  return (
    <TwoPartScreen
      rightPartImage={sideURL}
      docTitle="Validating invite..."
      leftChildren={
        <div className={`${TwoPartScreenStyles.card} ${TwoPartScreenStyles.show}`}>
          <div className={TwoPartScreenStyles.title}>Validating invite URL</div>
          {errorMsg ? (
            <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div>
          ) : (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: '18rem' }}
            >
              <ProgressCircle size={80} />
            </div>
          )}
        </div>
      }
    />
  );
};

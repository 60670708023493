import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TwoPartScreen, TwoPartScreenStyles } from 'iqm-framework';
import { SVG_HOST_URL } from 'factor';

import { useInitData } from 'utils/useInitData';

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/calendar-blue.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/calendar-teal.svg`;

export const InviteExpiredPage = () => {
  const history = useHistory();
  const { sideURL } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });

  const renderLeftChildren = () => {
    return (
      <div className={`${TwoPartScreenStyles.card} ${TwoPartScreenStyles.show}`}>
        <div className={TwoPartScreenStyles.title}>Invitation Expired</div>
        <div className={TwoPartScreenStyles.subtitle}>Uh Oh! Invitation link is expired</div>
        <div className={TwoPartScreenStyles.subtitle}>
          But don't worry, happens to the best of us.
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!history.location.search) {
      history.push('/404');
    }
  }, [history]);

  return (
    <TwoPartScreen
      rightPartImage={sideURL}
      docTitle="Invitation Expired"
      leftChildren={renderLeftChildren()}
    />
  );
};

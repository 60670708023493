import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import {
  TwoPartScreen,
  TwoPartScreenStyles,
  validationUtils,
  useAdblockCheck,
} from 'iqm-framework';
import { SVG_HOST_URL, TextField, Button } from 'factor';

import { API } from 'api';
import { useInitData } from 'utils/useInitData';

interface StateType {
  emailAddress: string;
}

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/email-message.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/brand/onboarding1Asset2.svg`;

export const ResetPasswordEmailPage = () => {
  const location = useLocation<StateType>();
  const [emailAddress, setEmailAddress] = useState<string>(location.state?.emailAddress || '');
  const [emailValidity, setEmailValidity] = useState<boolean>(
    location.state?.emailAddress
      ? validationUtils.validateEmail(location.state.emailAddress)
      : false,
  );
  const [visibleCard, setVisibleCard] = useState<1 | 2>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [adblockEnabled] = useAdblockCheck();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');

  const { sideURL } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });

  const handleSend = async (isResend = false) => {
    setErrorMsg('');
    setLoading(true);
    const res = await API.Password.sendResetPasswordEmail(emailAddress);
    if (res.success) {
      setVisibleCard(2);
      if (isResend) {
        setSuccessMsg(res.data || '');
        setTimeout(() => {
          setSuccessMsg('');
        }, 8000);
      }
    } else {
      setErrorMsg(get(res, 'errorObjects[0].error', ''));
    }
    setLoading(false);
  };

  return (
    <TwoPartScreen
      rightPartImage={sideURL}
      docTitle="Reset Password"
      leftChildren={
        <>
          <div
            className={`${TwoPartScreenStyles.card} ${
              visibleCard === 1 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
            }`}
          >
            <div className={TwoPartScreenStyles.title}>Reset your password</div>
            <div className={TwoPartScreenStyles.subtitle}>Enter your registered email</div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSend();
              }}
            >
              <TextField
                variant="withoutTickbox"
                name="email"
                value={emailAddress}
                onChange={setEmailAddress}
                label="Email ID"
                placeholder="Enter your email ID"
                className="mt-5"
                onValidate={setEmailValidity}
                inputAttributes={{
                  autoComplete: 'on',
                  autoFocus: true,
                }}
                validationRules={[
                  {
                    func: validationUtils.validateEmail,
                    error: () => {
                      return 'Please enter a valid email address';
                    },
                  },
                ]}
              />
              {errorMsg ? <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div> : null}
              <Button
                type="submit"
                variant="primary"
                iconName="Right"
                className={TwoPartScreenStyles.button}
                iconPosition="right"
                disabled={!emailValidity || adblockEnabled || loading}
              >
                Next
              </Button>
            </form>
          </div>
          <div
            className={`${TwoPartScreenStyles.card} ${
              visibleCard === 2 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
            }`}
          >
            <div className={TwoPartScreenStyles.title}>Reset your password</div>
            <p className={TwoPartScreenStyles.subtitle}>
              We’ve sent a password reset email, please check your inbox
            </p>
            <p className={TwoPartScreenStyles.subtitle}>
              Haven’t received an email? Please check your spam folder, or
            </p>
            <Button
              variant="primary"
              className={TwoPartScreenStyles.button}
              iconPosition="right"
              disabled={!emailValidity || adblockEnabled || loading}
              onClick={() => handleSend(true)}
            >
              Resend
            </Button>
            {errorMsg ? <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div> : null}
            {successMsg ? (
              <div className={TwoPartScreenStyles.successMessage}>{successMsg}</div>
            ) : null}
          </div>
        </>
      }
    />
  );
};

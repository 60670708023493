import React from 'react';

import styles from './MainContent.module.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
};

const MainContentComponent = (props: Props) => {
  const { className, children } = props;

  return <section className={`${styles.container} ${className || ''}`}>{children}</section>;
};

MainContentComponent.defaultProps = {
  className: '',
};

export const MainContent = MainContentComponent;

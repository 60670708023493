import { useCallback, useEffect, useState } from 'react';

import { InitData } from 'models/User';

export const useInitData = ({ ihb = '', nonIHB = '' }) => {
  const [data, setData] = useState<InitData | null>(null);
  const [sideURL, setSideURL] = useState<string>('');

  const getData = useCallback(() => {
    let count = 0;

    const i = setInterval(() => {
      const newData = localStorage.getItem('initData');
      if (newData) {
        clearInterval(i);
        const parsed = JSON.parse(newData) as InitData;
        setData(parsed);
        setSideURL(
          parsed.ihpOwner
            ? parsed.pages.landingPages[0] || ihb
            : parsed.pages.landingPages[0] || nonIHB,
        );
      } else {
        count += 1;
        if (count > 50) {
          clearInterval(i);
        }
      }
    }, 200);
  }, [ihb, nonIHB]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    sideURL,
  };
};

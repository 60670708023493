import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TwoPartScreen, TwoPartScreenStyles, useAdblockCheck } from 'iqm-framework';
import { SVG_HOST_URL, TextField, Button } from 'factor';

import { API } from 'api';
import { VerifyLinkResponse } from 'api/customer';
import { useInitData } from 'utils/useInitData';

import styles from './index.module.scss';

interface LocationData extends VerifyLinkResponse {
  hash: string;
}

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/office-people.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/brand/onboarding1Asset2.svg`;

export const CreateWorkspacePage = () => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [workspaceUrl, setWorkspaceUrl] = useState<string>('');
  const [adblockEnabled] = useAdblockCheck();
  const history = useHistory();
  const location = useLocation<LocationData>();
  const [loading, setLoading] = useState<boolean>(false);

  const { sideURL, data: initData } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });
  const workspaceURLSuffix = initData?.workspaceURLSuffix || '';

  const createWorkspace = async () => {
    const formatedWorkspaceUrl = workspaceUrl.endsWith('-')
      ? workspaceUrl.slice(0, -1)
      : workspaceUrl;
    setWorkspaceUrl(formatedWorkspaceUrl);

    if (!location.state?.inviteEmail) {
      setErrorMsg('Wrong invite link');
      return;
    }

    setLoading(true);
    const res = await API.Customer.createWorkspace({
      domain: formatedWorkspaceUrl,
      creatorEmail: location.state.inviteEmail,
      inviteHash: location.state.hash,
    });
    setLoading(false);

    const domain = `${formatedWorkspaceUrl}${workspaceURLSuffix}`;

    if (res.success) {
      history.push({
        pathname: '/create-password',
        state: { ...location.state, invitedToWorkspace: domain },
      });
    } else if (res.errorObjects?.length) {
      setErrorMsg(res.errorObjects[0].error);
    }
  };

  const onChangeWorkspaceUrl = (value: string) => {
    setWorkspaceUrl(value.replace(/[^A-Z\-0-9]+/gi, '').replace(/-+/gi, '-'));
  };

  return (
    <TwoPartScreen
      rightPartImage={sideURL}
      docTitle="Create Workspace"
      leftChildren={
        <>
          <div className={`${TwoPartScreenStyles.card} ${TwoPartScreenStyles.show}`}>
            <div className={TwoPartScreenStyles.title}>Claim workspace URL</div>
            <div className={TwoPartScreenStyles.subtitle}>Create workspace URL</div>
            <div className={TwoPartScreenStyles.subtitle}>
              <strong>{location.state?.organizationName || 'IHP'}</strong>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                createWorkspace();
              }}
            >
              <div>
                <TextField
                  name="WorkspaceURL"
                  label="Enter workspace URL"
                  type="text"
                  variant="withoutTickbox"
                  helpActions={<div className={styles.suffix}>{workspaceURLSuffix}</div>}
                  className={styles.workspaceURLField}
                  value={workspaceUrl}
                  onChange={onChangeWorkspaceUrl}
                  inputAttributes={{
                    autoFocus: true,
                  }}
                />
              </div>
              <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div>

              <Button
                variant="primary"
                iconName="Right"
                className={TwoPartScreenStyles.button}
                iconPosition="right"
                disabled={adblockEnabled || workspaceUrl.trim().length === 0 || loading}
                type="submit"
              >
                Next
              </Button>
            </form>
          </div>
        </>
      }
    />
  );
};

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TwoPartScreen, TwoPartScreenStyles, useAdblockCheck } from 'iqm-framework';
import { SVG_HOST_URL, Button, ProgressCircle } from 'factor';

import { PasswordField } from 'components/PasswordField';
import { API } from 'api';
import { hintsList, THint } from 'models/Password';
import { useInitData } from 'utils/useInitData';

import styles from './index.module.scss';

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/office-people.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/brand/onboarding1Asset2.svg`;

// eslint-disable-next-line
declare let PasswordCredential: any;

export const ResetPasswordPage = () => {
  const [visibleCard, setVisibleCard] = useState<1 | 2>(1);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [passwrd, setPasswrd] = useState<string>('');
  const [hints, setHints] = useState<THint[]>(hintsList);
  const [adblockEnabled] = useAdblockCheck();
  const history = useHistory();
  const mounted = useRef<boolean>(false);

  const { sideURL } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });

  useEffect(() => {
    const verifyHash = async (hash: string) => {
      const hash1 = hash.replace(/\s/g, '+');
      const res = await API.Password.verifyEmailLinkHash(hash1);
      if (res?.success && res?.data?.isValid) {
        setEmailAddress(res.data.resetEmail || '');
      } else if (res?.data?.invalidReason) {
        setErrorMsg(res.data.invalidReason);
      } else {
        setErrorMsg('Invalid link. Please contact your admin.');
      }
      setLoading(false);
    };

    if (history.location && mounted.current === false) {
      mounted.current = true;
      const hash = new URLSearchParams(history.location.search).get('hash');
      if (hash) {
        verifyHash(hash);
      }
    }
  }, [history.location]);

  useEffect(() => {
    if (passwrd.length) {
      const hintsToSave = hintsList.map((h) => ({ ...h, match: h.regex.test(passwrd) }));
      setHints(hintsToSave);
    } else {
      setHints(hintsList);
    }
  }, [passwrd]);

  const handleCreatePassword = async () => {
    if (history.location) {
      // logic to prompt save password popup in chrome and edge while resetting password
      if ('PasswordCredential' in window) {
        const credential = new PasswordCredential({
          id: emailAddress,
          name: emailAddress,
          password: passwrd,
        });
        navigator.credentials.store(credential);
      }

      const inviteHash = new URLSearchParams(history.location.search)
        .get('hash')
        ?.replace(/\s/g, '+');
      if (inviteHash) {
        const res = await API.Password.resetFromEmail(emailAddress, passwrd, inviteHash);
        if (res.success) {
          setVisibleCard(2);
        } else if (res.errorObjects?.length) {
          setErrorMsg(res.errorObjects[0].error || '');
        }
      }
    }
  };

  const navigateToLogin = () => {
    history.push('/login', { emailAddress });
  };

  return (
    <TwoPartScreen
      rightPartImage={sideURL}
      docTitle="Reset Password"
      leftChildren={
        <>
          <div
            className={`${TwoPartScreenStyles.card} ${
              visibleCard === 1 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
            }`}
          >
            <div className={TwoPartScreenStyles.title}>
              {!loading && emailAddress ? 'Create new password' : 'Link Expired'}
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreatePassword();
              }}
            >
              {loading ? (
                <ProgressCircle size={64} />
              ) : (
                <div>
                  {emailAddress ? (
                    <>
                      <input type="password" style={{ opacity: '0', position: 'absolute' }} />
                      <PasswordField
                        className={styles.passwordField}
                        label="Create Password"
                        placeholder="Enter new password"
                        helpText="Hints for a stronger password"
                        value={passwrd}
                        onChange={setPasswrd}
                        name="password"
                        inputAttributes={{
                          autoComplete: 'new-password',
                        }}
                      />
                      <ul className={styles.hints}>
                        {hints.map((h) => (
                          <li
                            className={`${styles.hint} ${styles[h.match ? 'green' : 'red']}`}
                            key={h.label}
                          >
                            {h.label}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div>
                  )}
                </div>
              )}

              <Button
                variant="primary"
                iconName="Right"
                className={TwoPartScreenStyles.button}
                iconPosition="right"
                disabled={
                  adblockEnabled ||
                  loading ||
                  !emailAddress ||
                  !passwrd.length ||
                  hints.find((hint) => !hint.match)
                }
                type="submit"
              >
                Next
              </Button>
            </form>
          </div>
          <div
            className={`${TwoPartScreenStyles.card} ${
              visibleCard === 2 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
            }`}
          >
            <div className={TwoPartScreenStyles.title}>Password reset successful</div>
            <div className={TwoPartScreenStyles.subtitle}>
              Awesome, you've successfully updated your password
            </div>
            <Button
              variant="primary"
              iconName="Right"
              className={TwoPartScreenStyles.button}
              iconPosition="right"
              onClick={navigateToLogin}
            >
              Continue Login
            </Button>
          </div>
        </>
      }
    />
  );
};

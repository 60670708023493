import axios, { AxiosInstance } from 'axios';
import { ORIGIN_URL, BASIC_AUTH_HEADER_VALUE } from 'iqm-framework';

import { localStorageService } from 'services/localStorage';
import { HEADER_TOKEN_NAME } from 'config';

import * as Password from './password';
import * as Customer from './customer';

export type IQMInstanceConfigParams = {
  apiToken: string;
  domainURL: string;
};

export type Params = { [key: string]: string | number };

let IQMInstance: AxiosInstance;
let authInstance: AxiosInstance;

export function createIqmInstance(config: IQMInstanceConfigParams) {
  const instance = axios.create({
    ...config,
    baseURL: `https://${config.domainURL}/api`,
    headers: {
      [HEADER_TOKEN_NAME]: config.apiToken,
    },
  });

  instance.interceptors.response.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: any) => {
      return response;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: any) => {
      if (axios.isCancel(response)) {
        const res = {
          response: {
            data: response,
          },
        };

        throw res;
      }
      if (response.response.status === 401) {
        localStorageService.removeBaseInfo();
        window.location.reload();
      }
      return Promise.reject(response);
    },
  );

  IQMInstance = instance;
}

export function createAuthInstance() {
  authInstance = axios.create({
    baseURL: `${ORIGIN_URL}/api`,
    headers: {
      Authorization: BASIC_AUTH_HEADER_VALUE,
    },
  });
}

export function getInstance(): AxiosInstance {
  return IQMInstance || axios;
}

export function getAuthInstance(): AxiosInstance {
  return authInstance;
}

export const API = {
  Password,
  Customer,
};

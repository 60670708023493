import { getAuthInstance } from 'api';
import { AxiosResponse } from 'axios';
import { WithResponse } from 'models/API';

export interface VerifyLinkResponse {
  isIHPCustomer: boolean;
  isExpired?: boolean;
  inviteEmail?: string;
  invitedToWorkspace?: string;
  organizationName?: string;
  workspaceDomain?: string; // if a workspace was already created with this invite
  isInviteProcessed?: boolean; // if user has already created (workspace AND) password with this invite, this flag will be true
  isInviteCancelled?: boolean; // if invite is cancelled before invitee tries to use the link
  isNewUser?: boolean;
  isInviteDeleted?: boolean;
}

export const verifyInviteLinkHash = async (
  inviteHash: string,
): Promise<WithResponse<VerifyLinkResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<VerifyLinkResponse>> = await getAuthInstance().post(
      '/v3/ua/invite/validate',
      { inviteHash },
    );
    return response.data;
  } catch (e) {
    return e.response?.data;
  }
};

interface CreateRequestData {
  creatorEmail: string;
  domain: string;
  inviteHash: string;
}

export const createWorkspace = async (
  createData: CreateRequestData,
): Promise<WithResponse<string>> => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getAuthInstance().post(
      '/v3/ua/workspace',
      createData,
    );

    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

interface VerifyEmailRequestData {
  workspaceDomain: string;
  email: string;
}

interface VerifyEmailResponse {
  isValid: boolean;
  isWorkspaceAllowed: boolean;
}

export const verifyEmail = async (
  data: VerifyEmailRequestData,
): Promise<WithResponse<VerifyEmailResponse>> => {
  try {
    const res: AxiosResponse<WithResponse<VerifyEmailResponse>> = await getAuthInstance().post(
      '/v3/ua/user/email/validate',
      data,
    );

    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const verifyWorkspaceDomain = async (
  data: VerifyEmailRequestData,
): Promise<WithResponse> => {
  try {
    const res: AxiosResponse<WithResponse> = await getAuthInstance().post(
      '/v3/ua/user/workspace/validate',
      data,
    );

    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const findMyWorkspace = async (email: string): Promise<WithResponse<string>> => {
  try {
    const res: AxiosResponse<WithResponse<string>> = await getAuthInstance().post(
      '/v3/ua/find-my-workspace',
      { email },
    );

    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const resendInvite = async (inviteHash: string): Promise<WithResponse<string>> => {
  try {
    const res: AxiosResponse<WithResponse<string>> = await getAuthInstance().post(
      '/v3/ua/customer/invite/re-send',
      { inviteHash },
    );

    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getIntercomId = async (): Promise<WithResponse<{ chatbotAppId: string }>> => {
  try {
    const res: AxiosResponse<WithResponse<{ chatbotAppId: string }>> = await getAuthInstance().get(
      `/v3/ua/st/chatbot`,
    );
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

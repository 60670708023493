import React, { useState } from 'react';
import { TextField, Icon } from 'factor';

import styles from './index.module.scss';

interface Props {
  className: string;
  name: string;
  placeholder: string;
  label?: string;
  helpText?: string;
  value: string;
  onChange: (val: string) => void;
  inputAttributes: { [key: string]: string | boolean };
  inputRef?: (ref: HTMLInputElement) => void;
}

export const PasswordField = (props: Props) => {
  const {
    className,
    inputAttributes,
    inputRef,
    label,
    name,
    helpText,
    onChange,
    placeholder,
    value,
  } = props;

  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <TextField
      className={`${className} ${styles.passwordField}`}
      name={name}
      placeholder={placeholder}
      label={label}
      type={passwordVisible ? 'text' : 'password'}
      variant="withoutTickbox"
      value={value}
      onChange={onChange}
      helpText={helpText}
      helpActions={
        <span
          className={styles.togglePasswordVisibilityIcon}
          onClick={() => {
            setPasswordVisible((val) => !val);
          }}
        >
          <Icon name={passwordVisible ? 'EyeOff' : 'Eye'} />
        </span>
      }
      inputAttributes={inputAttributes}
      inputRef={inputRef}
      disableLogging
    />
  );
};

PasswordField.defaultProps = {
  label: null,
  helpText: null,
  inputRef: null,
};

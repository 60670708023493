const BASE_INFO_NAME = 'authorizedUser';

export type LocalData<T> = {
  _time: Date;
  data: T;
};

export function saveLocal<T>(key: string, data: T): void {
  window.localStorage.setItem(
    key,
    JSON.stringify({
      _time: Date.now(),
      data,
    }),
  );
}

export function getLocal<T>(key: string): LocalData<T> {
  const data = window.localStorage.getItem(key) as string;
  return JSON.parse(data) || null;
}

function getBaseInfo<T>(key: string): T {
  const data = window.localStorage.getItem(key) as string;
  return JSON.parse(data) || null;
}

export function removeLocal(key: string): void {
  window.localStorage.removeItem(key);
}

export const localStorageService = {
  saveBaseInfo<T>(data: T) {
    saveLocal<T>(BASE_INFO_NAME, data);
  },
  getBaseInfo<T>() {
    return getBaseInfo<T>(BASE_INFO_NAME);
  },
  removeBaseInfo() {
    removeLocal(BASE_INFO_NAME);
  },
  saveLocal,
  getLocal,
};

export interface THint {
  regex: RegExp;
  label: string;
  match: boolean;
}

export const hintsList: THint[] = [
  {
    regex: /(?=.{8,})/g,
    label: 'contains at least 8 characters',
    match: false,
  },
  {
    regex: /(?=.*[A-Z])/g,
    label: 'contains at least one uppercase',
    match: false,
  },
  {
    regex: /(?=.*[0-9])/g,
    label: 'contains at least one number',
    match: false,
  },
  {
    regex: /(?=.*[!@#$%^&*])/g,
    label: 'contains at least one special character (like !@#$%^&*)',
    match: false,
  },
];

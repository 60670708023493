import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TwoPartScreen, TwoPartScreenStyles, useAdblockCheck } from 'iqm-framework';
import { SVG_HOST_URL, TextField, Button, Pill } from 'factor';

import { API } from 'api';
import { useInitData } from 'utils/useInitData';

interface LocationData {
  userEmail: string;
}

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/office-people.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/brand/onboarding1Asset2.svg`;

export const EnterWorkspacePage = () => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [workspaceUrl, setWorkspaceUrl] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [adblockEnabled] = useAdblockCheck();
  const history = useHistory();
  const location = useLocation<LocationData>();

  const { sideURL } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });

  useEffect(() => {
    if (!location.state?.userEmail) {
      history.push('/login');
    } else {
      setEmailAddress(location.state.userEmail);
    }
  }, [history, location]);

  // reset error if user changes workspace domain field
  useEffect(() => {
    setErrorMsg('');
  }, [workspaceUrl]);

  const verifyWorkspace = async () => {
    if (!location.state?.userEmail) {
      return;
    }

    const workspaceDomain = workspaceUrl.replace(/^https?:\/\//, '');

    const res = await API.Customer.verifyWorkspaceDomain({
      workspaceDomain,
      email: emailAddress,
    });

    if (res.success) {
      window.location.href = `https://${workspaceDomain}/#/login?email=${btoa(emailAddress)}`;
    } else if (res.errorObjects?.length) {
      setErrorMsg(res.errorObjects[0].error);
    } else {
      setErrorMsg("You don't seem to have access to this workspace");
    }
  };

  return (
    <TwoPartScreen
      rightPartImage={sideURL}
      docTitle="Enter Workspace"
      leftChildren={
        <>
          <div className={`${TwoPartScreenStyles.card} ${TwoPartScreenStyles.show}`}>
            <div className={TwoPartScreenStyles.title}>Enter workspace URL</div>
            <div className={TwoPartScreenStyles.subtitle}>
              Let us redirect you to your destination!
            </div>
            <div
              className={TwoPartScreenStyles.readonlyEmail}
              onClick={() => {
                history.push('/login');
              }}
            >
              <Pill className="mb-4" label={emailAddress} iconName="EditAlt" iconPosition="right" />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                verifyWorkspace();
              }}
            >
              <div>
                <TextField
                  name="WorkspaceURL"
                  label="Enter workspace URL"
                  type="text"
                  variant="withoutTickbox"
                  className="mt-3"
                  value={workspaceUrl}
                  onChange={setWorkspaceUrl}
                  inputAttributes={{
                    autoFocus: true,
                  }}
                />
              </div>
              <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div>

              <Button
                variant="primary"
                iconName="Right"
                className={TwoPartScreenStyles.button}
                iconPosition="right"
                disabled={adblockEnabled || workspaceUrl.trim().length === 0}
                type="submit"
              >
                Next
              </Button>
            </form>
            <Link
              className={TwoPartScreenStyles.link}
              to={{ pathname: '/find-my-workspace', state: { userEmail: emailAddress } }}
            >
              Forgot your URL?
            </Link>
          </div>
        </>
      }
    />
  );
};

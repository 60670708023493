import { NoMatchPage } from 'pages/NoMatchPage';

export type Route = {
  path: string;
  component: React.ComponentType<unknown>;
  exact?: boolean;
  private?: boolean;
};

export const fallbackRoute: Route = {
  path: '*',
  exact: true,
  component: NoMatchPage,
};

export const routes: Route[] = [fallbackRoute];

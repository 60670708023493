import React from 'react';
import { TwoPartScreen, TwoPartScreenStyles } from 'iqm-framework';
import { SVG_HOST_URL } from 'factor';

const RIGHT_PART_IMAGE_URL = `${SVG_HOST_URL}/misc/404-error.svg`;

export const ErrorPage = () => (
  <TwoPartScreen
    rightPartImage={RIGHT_PART_IMAGE_URL}
    for404Page
    docTitle="Error"
    leftChildren={
      <div className={`${TwoPartScreenStyles.card} ${TwoPartScreenStyles.show}`}>
        <div className={TwoPartScreenStyles.title}>Oops! Page not found</div>
        <div className={TwoPartScreenStyles.subtitle}>
          Something went wrong. We can't seem to find the page you are looking for.
        </div>
        <div className={`${TwoPartScreenStyles.subtitle} mt-4`}>Please check your URL.</div>
        {/* <Button
          variant="primary"
          iconName="Right"
          className={TwoPartScreenStyles.button}
          iconPosition="right"
          onClick={() => {
            history.push('/login');
          }}
        >
          Take me to Login
        </Button>s */}
      </div>
    }
  />
);

import React, { useEffect } from 'react';

const DefaultPage = () => {
  useEffect(() => {
    const savedOwIds = JSON.parse(localStorage.getItem('owIds') || '[]') || [];
    const currentIndex = Number.parseInt(localStorage.getItem('transitionOWIdIndex') ?? '', 10);
    // redirect to dashboard from here
    if (!Number.isNaN(currentIndex) && currentIndex < savedOwIds.length) {
      localStorage.removeItem('transitionOWIdIndex');
      setTimeout(() => {
        window.location.href = `/dashboard/u/${currentIndex}/`;
      }, 0);
    } else {
      window.location.href = '/dashboard/u/0/';
    }
  }, []);

  return <div>Redirecting...</div>;
};

export { DefaultPage };

import { AxiosResponse } from 'axios';

import { getAuthInstance } from 'api';
import { WithResponse } from 'models/API';

export const sendResetPasswordEmail = async (email: string): Promise<WithResponse<string>> => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getAuthInstance().post(
      '/v3/ua/user/reset-password',
      { email },
    );
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

interface VerifyLinkResponse {
  isValid: boolean;
  resetEmail?: string;
  invalidReason?: string;
}

export const verifyEmailLinkHash = async (
  inviteHash: string,
): Promise<WithResponse<VerifyLinkResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<VerifyLinkResponse>> = await getAuthInstance().post(
      '/v3/ua/user/reset-password/validate',
      { inviteHash },
    );
    return response.data;
  } catch (e) {
    return e.response?.data;
  }
};

export const resetFromEmail = async (
  email: string,
  password: string,
  inviteHash: string,
): Promise<WithResponse<string>> => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getAuthInstance().post(
      '/v3/ua/user/update-password',
      { email, password, inviteHash },
    );
    return response.data;
  } catch (e) {
    return e.response?.data;
  }
};

interface CreatePasswordRequestData {
  email: string;
  password: string;
  inviteHash: string;
}

interface CreatePasswordResponse {
  /* eslint-disable camelcase */
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  /* eslint-enable camelcase */
  scope: string;
  owId: number;
  redirectionUrl?: string;
}

export const createPassword = async (
  data: CreatePasswordRequestData,
  workspaceDomain: string,
): Promise<WithResponse<CreatePasswordResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<CreatePasswordResponse>> =
      await getAuthInstance().post('/v3/ua/sign-up', data, {
        headers: {
          'x-iaa-host': workspaceDomain,
        },
      });

    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

import { createAction } from 'utils/actions';
import { applicationConstants } from './constants';

export const applicationActions = {
  toggleSidebarMenu() {
    return createAction<void>(applicationConstants.TOGGLE__SIDEBAR_MENU);
  },
};

export interface ToggleSidebarMenu {
  toggleSidebarMenu: () => void;
}
